import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { Circle } from "./Circle";
import { Network } from "./Network";

export interface CircularImageProps extends BoxProps {
  adornment: "top" | "bottom";
}

export function CircularImage(props: CircularImageProps) {
  const { adornment, children, ...chakra } = props;

  let bottom: string | undefined = undefined;
  let right: string | undefined = undefined;
  let top: string | undefined = undefined;
  let left: string | undefined = undefined;

  if (adornment === "bottom") {
    bottom = "-6%";
    right = "-18%";
  }
  else {
    top = "-15%";
    left = "-20%";
  }

  return (
    <Box
      position="relative" {...chakra}
    >
      <Network
        size="80%"
        position="absolute"
        bottom={bottom}
        right={right}
        top={top}
        left={left}
        transform="rotate(-25deg)" />
      <Circle w="100%" overflow="hidden">
        {children}
      </Circle>
    </Box>
  );
}