import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { Section } from "../../layout/Section";
import { Stack } from "@chakra-ui/react";

export function AboutUsHero() {
  return (
    <Section>
      <TextAndVisual
        mr={{
          lg: "-50px",
          xl: "-100px"
        }}
        textPart={
          <Stack spacing="5">
            <Hero>The bridge between business and code</Hero>
            <BodyText>
              We are Indevit. It would be easy to call
              us ERP experts, CMMS masters, MES and MOM wizards
              or just software developers. And while that is all
              true, we like to see ourselves as bridge builders.
              Because our work’s pride and joy comes from bridging
              the gap between companies’ business needs and
              high-quality software. Our focus is on solving problems,
              not simply writing more code.
            </BodyText>
          </Stack>
        }
        visualPart={<StaticImage
          src="../../../images/indevit_sofa_full.jpg"
          alt=""
          placeholder="blurred" />}
      />
    </Section>
  );
}