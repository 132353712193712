import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { AboutUsContent } from "../components/sections/aboutUs/AboutUsContent";
import { AboutUsHero } from "../components/sections/aboutUs/AboutUsHero";

function AboutUsPage() {
  return (
    <Layout heroSection={<AboutUsHero />} isHeroShort>
      <AboutUsContent />
    </Layout>
  )
}

export default AboutUsPage;

export const Head = () => (
  <MetaTags title="About us" />
)