import { Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Section } from "../../layout/Section";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { CircularImage } from "../../visuals/CircularImage";

export function AboutUsContent() {
  return (<>
    <Section pt="0">
      <Stack spacing={{
        base: "16",
        lg: "24"
      }}>
        <TextAndVisual
          isVisualLeft
          textPart={
            <>
              <BodyText>
                We mostly work with large and middle-sized companies and organisations,
                who need a trusted long-term partner to take an all-round look at their IT challenges.
                As we are passionate about truly getting to know our customers’ industries and processes,
                they can safely count on us to provide a solution that tackles their challenges from all
                angles.
              </BodyText>
              <BodyText mt="4">
                Our ability to translate business into code also comes in handy when we work
                with programmers outside our own organisation. Our extensive experience and knowledge
                from various industries help us grasp the scope of any software project and communicate
                realistic project budgets and timeframes to our customers. We promise cost-efficient
                quality, but also honesty and transparency. Communication is the key to any IT project,
                and we are easy to get a hold of. All of us.
              </BodyText>
            </>
          }
          visualPart={
            <CircularImage
              adornment="top"
              marginX={{
                base: "20px"
              }}

            >
              <StaticImage
                src="../../../images/marica_working1.jpg"
                alt=""
                placeholder="blurred"
              />
            </CircularImage>
          }
        />
        <TextAndVisual
          textPart={
            <BodyText>
              The compact team behind Indevit is united
              by a genuine interest in problem solving and learning.
              Our company was founded in 2011 by Christoffer Smeds
              and Peter Hertsbacka, who built our foundation on
              extensive industrial expertise. As we are now growing,
              our focus continues to be on providing companies holistic
              software solutions that move businesses forward.
            </BodyText>
          }
          visualPart={
            <CircularImage
              adornment="bottom"
              marginX={{
                base: "20px"
              }}
           
            >
              <StaticImage
                src="../../../images/christoffer_and_peter.jpg"
                alt="Christoffer and Peter"
                placeholder="blurred"
              />
            </CircularImage>
          }
        />
      </Stack>
    </Section>
  </>)
}