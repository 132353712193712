import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

export interface CircleProps extends BoxProps {
}

export function Circle(props: CircleProps) {
  const { ...chakra } = props;
  return (
    <Box
      borderRadius="50%"
      sx={{ aspectRatio: "1 / 1" }}
      {...chakra} />
  )
}