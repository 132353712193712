import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

export interface NetworkProps extends BoxProps {
  size: string | string[]
}

export function Network(props: NetworkProps) {
  const { size, ...chakra } = props;
  return (
    <Box as="svg" width={size} viewBox="12 29 259 226" {...chakra}>
      <g>
        <clipPath id="cp0">
          <path transform="matrix(1,0,0,-1,0,283.465)" d="M 0 283.465 L 283.465 283.465 L 283.465 0 L 0 0 Z " />
        </clipPath>
        <g>
          <path transform="matrix(1,0,0,-1,256.1459,184.092)" d="M 0 0 C -3.686 3.225 -6.126 7.096 -7.318 11.613 C -8.509 16.126 -8.79 20.069 -8.157 23.44 C -7.525 26.808 -6.757 29.373 -5.856 31.134 C -4.957 32.893 -3.674 33.994 -2.003 34.436 C .744 35.161 3.785 33.782 7.118 30.307 C 10.452 26.829 12.482 23.715 13.207 20.969 C 14.555 15.863 13.819 11.39 11 7.55 C 8.181 3.707 4.513 1.191 0 0 " />
          <path transform="matrix(1,0,0,-1,82.9326,250.5361)" d="M 0 0 C -4.415 -2.12 -8.934 -2.841 -13.556 -2.16 C -18.174 -1.48 -21.909 -.188 -24.759 1.721 C -27.607 3.626 -29.663 5.342 -30.928 6.863 C -32.191 8.381 -32.699 9.994 -32.448 11.703 C -32.034 14.514 -29.571 16.768 -25.064 18.465 C -20.555 20.163 -16.894 20.804 -14.083 20.39 C -8.859 19.621 -5.036 17.185 -2.615 13.083 C -.19 8.98 .68 4.618 0 0 " />
          <path transform="matrix(1,0,0,-1,37.5649,109.1687)" d="M 0 0 C -1.895 3.171 -2.746 6.515 -2.552 10.033 C -2.357 13.548 -1.643 16.442 -.405 18.714 C .831 20.984 1.979 22.646 3.034 23.7 C 4.088 24.753 5.265 25.245 6.566 25.173 C 8.706 25.055 10.567 23.359 12.151 20.091 C 13.735 16.821 14.468 14.115 14.35 11.975 C 14.13 7.999 12.562 4.96 9.647 2.86 C 6.732 .759 3.515 -.194 0 0 " />
          <path transform="matrix(1,0,0,-1,118.5136,127.676898)" d="M 0 0 C -11.131 0 -23.148 -1.205 -36.046 -3.611 C -62.193 -8.489 -85.196 -20.718 -105.056 -40.299 C -100.182 -66.426 -87.433 -88.307 -66.794 -105.924 C -51.086 -119.34 -32.785 -126.05 -11.892 -126.05 C -5.357 -126.05 1.434 -125.394 8.476 -124.08 C 24.376 -121.114 42.908 -110.892 64.054 -93.417 C 85.185 -75.945 94.286 -59.255 91.319 -43.354 C 89.515 -33.68 83.798 -25.933 74.189 -20.093 C 64.567 -14.239 50.322 -8.802 31.463 -3.804 C 21.903 -1.267 11.418 0 0 0 M .003 -1.333 C 11.252 -1.333 21.722 -2.598 31.121 -5.092 C 49.753 -10.031 64.01 -15.461 73.497 -21.232 C 82.732 -26.846 88.288 -34.371 90.009 -43.599 C 92.865 -58.907 83.846 -75.322 63.205 -92.389 C 42.334 -109.636 23.839 -119.858 8.232 -122.769 C 1.303 -124.062 -5.468 -124.717 -11.892 -124.717 C -32.36 -124.717 -50.54 -118.053 -65.929 -104.91 C -86.043 -87.741 -98.719 -66.158 -103.613 -40.751 C -84.149 -21.738 -61.338 -9.685 -35.801 -4.921 C -23.039 -2.541 -10.994 -1.333 .001 -1.333 Z " />
          <path transform="matrix(1,0,0,-1,161.0585,77.13539)" d="M 0 0 C -6.944 0 -14.425 -.393 -22.442 -1.178 C -49.73 -3.857 -66.604 -12.612 -73.03 -27.456 C -76.94 -36.487 -76.528 -46.106 -71.824 -56.319 C -67.116 -66.551 -58.341 -79.02 -45.495 -93.705 C -32.64 -108.411 -14.01 -121.033 10.38 -131.592 C 26.804 -138.702 43.902 -142.256 61.685 -142.256 C 70.331 -142.256 79.143 -141.415 88.111 -139.734 C 98.67 -115.344 100.326 -90.074 93.056 -63.93 C 85.798 -37.8 68.372 -18.754 40.783 -6.81 C 30.299 -2.271 16.703 0 0 0 M .001 0 L .001 -1.333 C 16.442 -1.333 29.984 -3.587 40.253 -8.033 C 67.325 -19.753 84.658 -38.68 91.772 -64.287 C 98.856 -89.766 97.307 -114.748 87.167 -138.553 C 78.555 -140.126 69.986 -140.923 61.685 -140.923 C 44.17 -140.923 27.086 -137.372 10.909 -130.369 C -13.2 -119.931 -31.839 -107.301 -44.492 -92.827 C -57.183 -78.32 -65.972 -65.849 -70.613 -55.762 C -75.134 -45.945 -75.536 -36.6 -71.807 -27.986 C -65.62 -13.695 -48.967 -5.122 -22.312 -2.504 C -14.377 -1.727 -6.87 -1.333 0 -1.333 Z " />
          <path transform="matrix(1,0,0,-1,169.2892,128.00659)" d="M 0 0 C 2.223 -2.214 3.623 -4.789 4.198 -7.725 C 4.774 -10.659 4.791 -13.19 4.247 -15.319 C 3.704 -17.445 3.107 -19.053 2.459 -20.142 C 1.812 -21.229 .946 -21.88 -.14 -22.093 C -1.926 -22.443 -3.813 -21.437 -5.8 -19.078 C -7.788 -16.717 -8.957 -14.643 -9.307 -12.858 C -9.957 -9.539 -9.302 -6.71 -7.342 -4.372 C -5.382 -2.032 -2.934 -.575 0 0 " />
          <path transform="matrix(1,0,0,-1,91.0439,122.5361)" d="M 0 0 C 2.022 -1.584 3.409 -3.54 4.162 -5.871 C 4.914 -8.2 5.173 -10.256 4.938 -12.039 C 4.703 -13.82 4.374 -15.184 3.953 -16.132 C 3.532 -17.078 2.892 -17.691 2.03 -17.97 C .612 -18.428 -1.019 -17.793 -2.863 -16.068 C -4.707 -14.343 -5.859 -12.77 -6.317 -11.353 C -7.168 -8.719 -6.91 -6.356 -5.543 -4.266 C -4.177 -2.174 -2.329 -.752 0 0 " />
          <path transform="matrix(1,0,0,-1,147.7142,66.2112)" d="M 0 0 C 2.745 -1.441 4.818 -3.464 6.219 -6.069 C 7.619 -8.671 8.374 -11.057 8.481 -13.226 C 8.59 -15.392 8.495 -17.085 8.201 -18.302 C 7.907 -19.517 7.28 -20.385 6.316 -20.903 C 4.732 -21.755 2.657 -21.355 .092 -19.708 C -2.474 -18.059 -4.184 -16.442 -5.035 -14.858 C -6.618 -11.914 -6.825 -9.051 -5.657 -6.271 C -4.489 -3.49 -2.602 -1.4 0 0 " />
          <path transform="matrix(1,0,0,-1,155.5552,189.3894)" d="M 0 0 C 2.472 -1.298 4.339 -3.119 5.6 -5.465 C 6.86 -7.808 7.54 -9.956 7.637 -11.909 C 7.734 -13.86 7.649 -15.384 7.384 -16.48 C 7.12 -17.574 6.555 -18.355 5.687 -18.821 C 4.261 -19.588 2.392 -19.229 .083 -17.745 C -2.228 -16.261 -3.767 -14.804 -4.534 -13.378 C -5.959 -10.728 -6.146 -8.15 -5.094 -5.647 C -4.042 -3.142 -2.343 -1.26 0 0 " />
          <path transform="matrix(1,0,0,-1,184.7268,210.9652)" d="M 0 0 C 1.57 .718 3.167 .942 4.792 .671 C 6.415 .401 7.722 -.079 8.714 -.771 C 9.705 -1.461 10.419 -2.079 10.854 -2.624 C 11.289 -3.167 11.458 -3.739 11.358 -4.34 C 11.193 -5.328 10.31 -6.106 8.711 -6.674 C 7.11 -7.242 5.816 -7.444 4.828 -7.28 C 2.992 -6.974 1.661 -6.09 .835 -4.628 C .008 -3.166 -.27 -1.623 0 0 " />
          <path transform="matrix(1,0,0,-1,109.1018,29.737992)" d="M 0 0 C -25.041 -8.905 -44.656 -24.923 -58.824 -48.066 C -72.991 -71.192 -75.042 -96.925 -64.969 -125.251 C -59.55 -140.49 -46.552 -157.193 -25.981 -175.342 C -10.142 -189.309 4.119 -196.299 16.802 -196.299 C 20.586 -196.299 24.231 -195.677 27.733 -194.431 C 37.005 -191.134 43.761 -184.274 48.025 -173.87 C 52.3 -163.449 55.439 -148.529 57.423 -129.119 C 59.415 -109.689 55.947 -87.455 47.042 -62.413 C 38.13 -37.353 22.449 -16.549 0 0 M -.221 -1.496 C 21.605 -17.743 37.082 -38.385 45.786 -62.86 C 54.588 -87.613 58.057 -109.86 56.096 -128.984 C 54.137 -148.16 51.006 -163.091 46.792 -173.364 C 42.693 -183.365 36.131 -190.03 27.287 -193.175 C 23.944 -194.364 20.417 -194.966 16.802 -194.966 C 4.518 -194.966 -9.58 -188.027 -25.099 -174.342 C -45.402 -156.43 -58.394 -139.763 -63.713 -124.804 C -73.598 -97.01 -71.57 -71.425 -57.687 -48.762 C -43.88 -26.208 -24.548 -10.309 -.221 -1.496 " />
        </g>
      </g>
    </Box>

  )
}